<template>
  <div class="notas-credito mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            Lista notas crédito
          </v-card-title>
          <v-card-subtitle>
            Desde este apartado podrás ver el registro de las notas crédito
            realizadas en tu ventas.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :search="search"
                  :items="lista_notas"
                  :footer-props="{ itemsPerPageText: 'Notas' }"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row dense>
                        <v-col cols="4" class="px-0">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-btn color="black" dark @click="download()">
                            <v-icon>mdi-download</v-icon>
                            Descargar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.estado="{ item }">
                    <v-chip small color="error" v-if="!item.estado">
                      Anulada
                    </v-chip>
                    <v-chip small color="success" v-if="item.estado">
                      Activa
                    </v-chip>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <router-link :to="`/detalleNotaCredito/${item.id}`">
                          <v-btn
                            fab
                            small
                            class="my-1"
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </router-link>
                      </template>
                      <span>Ver nota credito</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  data: () => ({
    date: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    menu_1: false,
    menu_2: false,
    search: "",
    headers: [
      {
        text: "Fecha Creación",
        value: "fecha",
        align: "start",
        sortable: false,
        filterable: false,
      },
      {
        text: "Nota Crédito",
        value: "nota",
        sortable: false,
      },
      {
        text: "Factura",
        value: "factura",
        sortable: false,
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false,
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
        filterable: false,
      },
      {
        text: "Detalle",
        value: "detalle",
        sortable: false,
        filterable: false,
      },
    ],
    notas: [],
  }),
  methods: {
    loadNotas() {
      const body = {
        route: "/notas_credito",
        params: {
          date: JSON.stringify({ inicio: this.date, fin: this.date_fin }),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.notas = response.data.data;
        }
      });
    },
    download() {
      const name = "Notas-Credito";
      let data = this.lista_notas.map((nota) => {
        return {
          Columna_A: nota.fecha,
          Columna_B: nota.nota,
          Columna_C: nota.factura,
          Columna_D: nota.total,
        };
      });
      data.unshift({
        Columna_A: "Fecha creación",
        Columna_B: "Nota crédito",
        Columna_C: "Factura",
        Columna_D: "Total",
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  created() {
    this.loadNotas();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_notas() {
      const notas = this.notas;
      return notas.map((nota) => {
        nota.fecha = moment(nota.fecha).format("ll");
        nota.nota = this.$options.filters.folio(nota.nota);
        nota.factura = this.$options.filters.folio(nota.factura);
        return nota;
      });
    },
  },
  watch: {
    date(val) {
      this.loadNotas();
      return this.date;
    },
    date_fin(val) {
      this.loadNotas();
      return this.date_fin;
    },
  },
};
</script>
